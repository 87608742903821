import '../styles/verify-result.css'

import { Banner } from '@shopify/polaris'

export default function VerifyResult({ status }) {

    return <div className='verify-container'>
        {status && <Banner title='Verification Successful' status='success'>
            <p>You have successfully verified your email address. Purchase Order Pro app can now send purchase orders to your suppliers on your behalf.</p>
            <br /><p>To start sending Purchase Orders with your email address, login to the application again. </p>
            
            <br /><p>If you need help or have any doubts, contact our support at support@droidinfinity.xyz</p>
        </Banner>}

        {!status && <Banner title='Verification Failed' status='critical'>
            <p>We're unable to verify your email. This may happen if you have clicked a expired link or if our provider couldn't validate the email at the moment. Please restart the verification and try again.</p>
            <br /><p>Please note that the link is only valid for 24 hours. </p>
            <br /><p>If the issue persists, contact our support at support@droidinfinity.xyz for help. </p>
        </Banner>}
    </div>
}