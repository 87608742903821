import { Page } from '@shopify/polaris';
import { AlphaCard, } from '@shopify/polaris';
import { VerticalStack } from '@shopify/polaris';
import { Bleed } from '@shopify/polaris';
import { HorizontalStack } from '@shopify/polaris';
import { HorizontalGrid } from '@shopify/polaris';
import { Divider } from '@shopify/polaris';
import { Box } from '@shopify/polaris';

export const PolarisPage = (props) => Page(props);
export const PolarisCard = ({ children, background, padding, roundedAbove = 'sm' }) => AlphaCard({ children, background, padding, roundedAbove: roundedAbove });
export const PolarisColumns = (props) => HorizontalGrid(props);
export const PolarisStack = (props) => VerticalStack(props);
export const PolarisBleed = (props) => Bleed(props);
export const PolarisDivider = (props) => Divider(props);
export const PolarisInline = (props) => HorizontalStack(props);


export const PolarisBox = Box;
