import '../styles/header.css'
import BillingPlanCard from './BillingPlanCard';

export default function Pricing() {

    const billingPlans = [
        {
            planId: 1, title: 'Small Business', price: 7.99, trialPeriod: 3,
            features: [
                { title: '50 POs / month', info: 'Create & Send up-to 50 Purchase Orders per month', enabled: true },
                { title: 'Automatic PO Creation', info: 'The app creates the Purchase Order automatically when an Order is received on Shopify and sends it to the Supplier.', enabled: true },
                { title: 'Send PO from your Email', info: 'Send Purchase Orders to Supplier from your own email.', enabled: true },
                { title: 'Split POs', info: 'Create multiple POs for each Supplier when an order is received on Shopify.', enabled: true },
                { title: 'Receive Inventory from PO', info: 'Receive Inventory from the Supplier and update the Inventory Levels on Shopify.', enabled: true },
                { title: 'Packing Slips', info: 'Generate and send packing slips along with the purchase order in the email.', enabled: true },
                { title: 'Customize PO Template', info: 'Edit the Purchase Order PDF format to match your needs with the help of our professional developers. ', enabled: false },
                { title: 'Multi Currency', info: 'The app automatically converts the Unit Cost to Supplier Currency if the Supplier & Store currencies are different.', enabled: false },
                { title: 'WhatsApp Support', info: 'Contact our support team directly through WhatsApp to clarify your doubts & resolve issues, if any.', enabled: false },
                { title: '7 Days of free trial', info: '', enabled: true },
            ]
        },
        {
            planId: 2, title: 'Medium Business', price: 12.99, trialPeriod: 3,
            features: [
                { title: '100 POs / month', info: 'Create & Send up-to 100 Purchase Orders per month', enabled: true },
                { title: 'Automatic PO Creation', info: 'The app creates the Purchase Order automatically when an Order is received on Shopify and sends it to the Supplier.', enabled: true },
                { title: 'Send PO from your Email', info: 'Send Purchase Orders to Supplier from your own email.', enabled: true },
                { title: 'Split POs', info: 'Create multiple POs for each Supplier when an order is received on Shopify.', enabled: true },
                { title: 'Receive Inventory from PO', info: 'Receive Inventory from the Supplier and update the Inventory Levels on Shopify.', enabled: true },
                { title: 'Packing Slips', info: 'Generate and send packing slips along with the purchase order in the email.', enabled: true },
                { title: 'Customize PO Template', info: 'Edit the Purchase Order PDF format to match your needs with the help of our professional developers. ', enabled: true },
                { title: 'Multi Currency', info: 'The app automatically converts the Unit Cost to Supplier Currency if the Supplier & Store currencies are different.', enabled: false },
                { title: 'WhatsApp Support', info: 'Contact our support team directly through WhatsApp to clarify your doubts & resolve issues, if any.', enabled: false },
                { title: '7 Days of free trial', info: '', enabled: true },
            ]
        },
        {
            planId: 3, title: 'Large Business', price: 19.99, trialPeriod: 3,
            features: [
                { title: '250 POs / month', info: 'Create & Send up-to 250 Purchase Orders per month', enabled: true },
                { title: 'Automatic PO Creation', info: 'The app creates the Purchase Order automatically when an Order is received on Shopify and sends it to the Supplier.', enabled: true },
                { title: 'Send PO from your Email', info: 'Send Purchase Orders to Supplier from your own email.', enabled: true },
                { title: 'Split POs', info: 'Create multiple POs for each Supplier when an order is received on Shopify.', enabled: true },
                { title: 'Receive Inventory from PO', info: 'Receive Inventory from the Supplier and update the Inventory Levels on Shopify.', enabled: true },
                { title: 'Packing Slips', info: 'Generate and send packing slips along with the purchase order in the email.', enabled: true },
                { title: 'Customize PO Template', info: 'Edit the Purchase Order PDF format to match your needs with the help of our professional developers. ', enabled: true },
                { title: 'Multi Currency', info: 'The app automatically converts the Unit Cost to Supplier Currency if the Supplier & Store currencies are different.', enabled: true },
                { title: 'WhatsApp Support', info: 'Contact our support team directly through WhatsApp to clarify your doubts & resolve issues, if any.', enabled: true },
                { title: '7 Days of free trial', info: '', enabled: true },
            ]
        },
        // {
        //     planId: 11, title: 'Enterprise', price: -1, trialPeriod: 0,
        //     features: [
        //         { title: 'Upto 1500 POs / month', info: 'Create & Send up-to 1500 Purchase Orders per month.', enabled: true },
        //         { title: 'Automatic PO Creation', info: 'The app creates the Purchase Order automatically when an Order is received on Shopify and sends it to the Supplier.', enabled: true },
        //         { title: 'Send PO from your Email', info: 'Send Purchase Orders to Supplier from your own email.', enabled: true },
        //         { title: 'Split POs', info: 'Create multiple POs for each Supplier when an order is received on Shopify.', enabled: true },
        //         { title: 'Receive Inventory from PO', info: 'Receive Inventory from the Supplier and update the Inventory Levels on Shopify.', enabled: true },
        //         { title: 'Packing Slips', info: 'Generate and send packing slips along with the purchase order in the email.', enabled: true },
        //         { title: 'Customize PO Template', info: 'Edit the Purchase Order PDF format to match your needs with the help of our professional developers. ', enabled: true },
        //         { title: 'Multi Currency', info: 'The app automatically converts the Unit Cost to Supplier Currency if the Supplier & Store currencies are different.', enabled: true },
        //         { title: 'WhatsApp Support', info: 'Contact our support team directly through WhatsApp to clarify your doubts & resolve issues, if any.', enabled: true },
        //         { title: 'Additional Customizations', info: 'Additional customizations to support your business needs.', enabled: true },
        //     ]
        // }
    ]

    return <section className='billing-section'>
        <br></br>
        <br></br>
        <br></br>
        <h2 style={{ fontWeight: '700', fontSize: '24px', fontFamily: 'Montserrat', textAlign: 'center', color: '#9074EA' }}>Pricing</h2>
        <p style={{ fontWeight: '500', fontSize: '14px', fontFamily: 'Montserrat', textAlign: 'center', color: '#4B3C72', marginTop: '8px' }}>An plan for every type of business </p>
        <br></br>
        <br></br>

        {<div className='BillingCards'>
            {billingPlans.map((plan) => {
                return <div className='BillingCard' key={plan.planId}>
                    <BillingPlanCard plan={plan} />
                </div>
            })}
        </div>}
        
        <br></br>
        <p style={{textAlign: 'center', fontWeight: '500', fontSize: '14px', fontFamily: 'Montserrat', }}>For any queries, contact <a href='https://droidinfinity.com/contact' target={'_blank'} rel='follow' className='link'>support</a></p>
    </section>
}

