import '../styles/login.css'

import { Text, TextField, Button, InlineError } from '@shopify/polaris'
import { PolarisBleed, PolarisBox, PolarisCard, PolarisDivider, PolarisStack } from '../polaris'
import { useState } from 'react'
import { useCallback } from 'react';

export default function Login() {
    const [storeName, setStoreName] = useState('');
    const [hasError, setHasError] = useState(false);

    const onLogin = useCallback(() => {
        if (!storeName) {
            return setHasError(true);
        }

        setHasError(false);
        const shopId = storeName.replace('myshopify.com', '');
        window.open(`https://purchase-orders-pro-ltjid4obxa-uc.a.run.app/api/auth?shop=${shopId}.myshopify.com`, '_self');
    }, [storeName]);

    return <div className='login-container'>
        <div className='login-card'>
            <PolarisCard>
                <PolarisBox minWidth='300px'>
                    <PolarisStack gap='4'>
                        <div>
                            <Text as='h3' variant='headingMd'>Purchase Orders Pro</Text>
                            <p>Login to continue</p>
                        </div>
                        <span />

                        <TextField label={'Store Name'} value={storeName} onChange={setStoreName}
                            autoComplete inputMode='text' suffix='myshopify.com' id='storeName' />

                        {hasError && <InlineError message={'Enter a valid store name.'} fieldID={'storeName'}></InlineError>}

                        <PolarisBleed marginInline={'5'}><PolarisDivider /></PolarisBleed>

                        <Button primary onClick={() => onLogin()}>Login</Button>
                    </PolarisStack>
                </PolarisBox>
            </PolarisCard>
        </div>
    </div>
}