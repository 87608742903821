import { Button, Icon, Text, Tooltip } from '@shopify/polaris';
import { CircleTickMinor, CircleCancelMinor, InfoMinor } from '@shopify/polaris-icons';
import { PolarisCard, PolarisStack, PolarisInline, PolarisDivider, PolarisBleed } from '../polaris';

import '../styles/billing.css';

const BillingPlanCard = ({ plan }) => {

    return (
        <PolarisCard>
            <PolarisStack gap={'4'}>
                <PolarisStack gap={'3'} align='start' inlineAlign="start">
                    <Text as="h2" variant="headingMd" fontWeight='regular'>{plan.title}</Text>

                    {plan.price >= 0 && <PolarisInline wrap={false} blockAlign='end' gap={'05'}>
                        <Text as="span" variant="headingMd">$</Text>
                        <Text as="h3" variant="headingXl">{plan.price}</Text>
                        <span />
                        <Text as="span" variant="bodyMd"> / Month</Text>
                    </PolarisInline>}
                    {plan.price < 0 && <div style={{ paddingTop: '2px', paddingBottom: '2px' }}><Text as="span" variant="headingMd">Contact Sales</Text></div>}
                </PolarisStack>

                <PolarisBleed marginInline={'5'}><PolarisDivider /></PolarisBleed>

                <PolarisStack gap={'0'}>
                    {plan.features.map((feature, index) => {
                        return <PolarisInline key={index} gap={'1'} align='start' blockAlign="start" wrap={false}>
                            {feature.enabled && <Icon source={CircleTickMinor} color="primary" />}
                            {!feature.enabled && <Icon source={CircleCancelMinor} color="base" />}
                            <p className={feature.enabled ? 'BillingCard_feature' : 'BillingCard_feature_disabled'}>{feature.title}</p>
                            {feature.info && feature.enabled && <Tooltip content={feature.info}><Icon color='base' source={InfoMinor} /></Tooltip>}
                        </PolarisInline>
                    })}
                </PolarisStack>
            </PolarisStack>

            <div className="BillingCard_buttonContainer">
                <a className='billing-button' target={'_blank'} href={plan.price >= 0 ? 'https://apps.shopify.com/purchase-orders-pro': 'https://droidinfinity.com/contact'}>{plan.price >= 0 ? 'Start Trial' : 'Contact Us'}</a>
            </div>
        </PolarisCard>
    )
}

export default BillingPlanCard;

