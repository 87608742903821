import { PolarisBox, PolarisColumns, PolarisPage, PolarisStack } from '../polaris';
import { Text } from '@shopify/polaris'

export default function Features() {

    return <PolarisPage>
        <br></br>
        <h2 style={{ fontWeight: '700', fontSize: '24px', fontFamily: 'Montserrat', textAlign: 'center', color: '#9074EA' }}>Features</h2>
        <p style={{ fontWeight: '500', fontSize: '14px', fontFamily: 'Montserrat', textAlign: 'center', color: '#4B3C72', marginTop: '8px' }}>The Purchase Order system that you always wanted </p>
        <br></br>
        <br></br>

        <PolarisColumns columns={{ sm: 1, md: 2, lg: 3 }} gap={'8'} >
            <Feature title={'Automate Purchase Orders'}
                desc={'When the customer places their orders, the app can create and issue purchase orders on behalf of you to your suppliers.'}></Feature>

            <Feature title={'Minimal Setup'}
                desc={'Just install "Purchase Orders Pro" app and create your first professional purchase order and send the PDF to suppliers within minutes.'}></Feature>

            <Feature title={'Draft Purchase Orders'}
                desc={`Let the app make all the hard work of creating the PO when an order is received. You can review it & send it to your suppliers with a click of a button. `}></Feature>

            <Feature title={'Send From Your Own Email'}
                desc={'Send purchase orders reliably with your own professional email address to the suppliers to avoid any confusion by your supplier. '}></Feature>

            <Feature title={'Transfer Inventory'}
                desc={`Transfer incoming inventory to Shopify when you receive the items from your suppliers automatically - no manual effort required.`}></Feature>

            <Feature title={'Focus on Growth'}
                desc={`Save hours of your daily time and focus on building your great business. No more manual excel work needed.`}></Feature>

            <Feature title={'Auto Multi-Currency'}
                desc={`If the supplier currency is different than your store's currency, the app can automatically convert the cost price in supplier currency. `}></Feature>

            <Feature title={'Expert Support'}
                desc={`We're determined to help you grow your business. Our support team is always available to assist you incase you need us. `}></Feature>

            <Feature title={'Money Back Guarantee'}
                desc={`If the app does not work for you, contact us within 14 days for a refund. Continue only if it works for you! `}></Feature>
        </PolarisColumns>

    </PolarisPage>
}

function Feature({ title, desc }) {
    return <PolarisBox padding={'2'}><PolarisStack gap='2'>
        <Text as='h3' variant='headingMd' textAlign='center'><span style={{ fontFamily: 'Montserrat', color: '#4B3C72', textAlign: 'center', display: 'block' }}>{title}</span></Text>
        <Text as='p' variant='bodyMd' fontWeight='regular' textAlign='center'><span style={{ fontFamily: 'Montserrat', fontWeight: 500, textAlign: 'center', display: 'block' }}>{desc}</span></Text>
    </PolarisStack></PolarisBox>
}

