import '@shopify/polaris/build/esm/styles.css';
import { AppProvider } from '@shopify/polaris';
import Home from './pages/Home';

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import VerifyResult from './pages/VerifyResult';
import Login from './pages/Login';

const router = createBrowserRouter([
  { path: "/", element: <Home /> },
  { path: "/login", element: <Login /> },
  { path: "/verification/success", element: <VerifyResult status={true} /> },
  { path: "/verification/failure", element: <VerifyResult status={false} /> },
  { path: "*", element: <Home /> },
]);

function App() {

  return (<AppProvider>
    <RouterProvider router={router} />
  </AppProvider>);
}

export default App;
