import { PolarisColumns, PolarisInline } from '../polaris';
import '../styles/header.css'

export default function Header() {

    return <div>
        <section className='header'>
            <div className='header-content'>
                <PolarisInline blockAligh='center' gap='3'>
                    <img className='logo' alt='Purchase Orders Pro' src='./logo.png' />
                    <p className='app-name'>ULTIMATE <br />PURCHASE <br />ORDERS</p>
                </PolarisInline>
                <br /><br />

                <PolarisColumns columns={{ sm: 1, md: 2 }} gap={'4'} >
                    <div>
                        <span className='built-for-shopify'>Built for Shopify</span>

                        <p className='banner-title'>Create, Track &amp; Manage
                            Purchase Orders </p>

                        <p className='banner-desc'>Send Purchase Orders, Manage Suppliers &amp; Track Inventory all in one place. </p>
                        <p className='banner-desc'>Automatically create Purchase Orders and <i>split</i> it across Suppliers when you receive an order on Shopify. </p>
                        <p className='banner-desc'>Transfer Inventory directly to Shopify when you receive the items.</p>

                        <a href='https://apps.shopify.com/purchase-orders-pro' className='get-started'>Get Started</a>
                        <p className='banner-trial'>7 day free trial. No payment required. </p>
                    </div>

                    <div>
                        <img className='banner-image' alt='Purchase Order Management' src='./banner-image.png'></img>
                    </div>
                </PolarisColumns>
            </div>
        </section>
        <div className='header-bottom'></div>
    </div>
}

