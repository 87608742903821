import Features from '../components/Features';
import Header from '../components/Header';
import Pricing from '../components/Pricing';
import { PolarisDivider } from '../polaris';

export default function Home() {
    return (<>
        <Header />
        <Features />
        <br></br><br></br>
        <PolarisDivider />
        <Pricing />
        <br></br><br></br>
        <PolarisDivider />
        <footer style={{ marginTop: '48px', paddingBottom: '20px', textAlign: 'center' }}>
            <span style={{ fontFamily: 'Montserrat', fontSize: '14px' }}>© {new Date().getFullYear()} <a className='link' target={'_blank'} referrerPolicy='no-referrer' rel='follow' href='https://droidinfinity.com'>Droid Infinity</a></span>
        </footer>
    </>);
}
